<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  模版标题    -->
      <div slot="itemTemplateNameSlot" slot-scope="row">
        <div class="color-black font-weight-bold">{{ row.title }}【<span class="color-blue">{{ row.id }}</span>】</div>
      </div>
<!--  模版类型    -->
      <div slot="itemTemplateTypeSlot" slot-scope="row">
        <div :class="messageTemplateContentClassType[row.informType]">{{ computedMessageTemplateContentTypeMapText(row.informType) }}</div>
        <div class="mt-10">
          <div v-if="row.informType === 1" class="cur-pot color-blue" @click="handleShowTextContent(row.content)">富文本内容</div>
          <div v-if="row.informType === 2" class="w-100-w flex-center-center" @click="handleShowBigImg(row.pictureUrl)">
            <img class="w-50 text-center" :src="row.pictureUrl" alt="">
          </div>
          <div v-if="row.informType === 3" class="cur-pot color-blue">
            <a :href="row.linkUrl" target="_blank" @click="handleGoToLinkUrl(row.linkUrl)">链接内容</a>
          </div>
        </div>
      </div>
<!--  模版推送对象    -->
      <div slot="itemTemplatePushUserSlot" slot-scope="row">
        <div :class="messageTemplatePushUserClassType[row.pushObject]">{{ computedMessageTemplatePushUserListMapText(row.pushObject) }}</div>
      </div>
<!--  是否弹窗显示    -->
      <div slot="itemIsPopupStatusSlot" slot-scope="row">
        <div :class="messageTemplateIsPopupTypeClassType[row.isPopUp]">{{ computedMessageTemplateIsPopupTypeListMapText(row.isPopUp) }}</div>
      </div>
<!--  简介    -->
      <div slot="itemSContentSimpleSlot" slot-scope="row">
        <div>{{ row.intro }}</div>
      </div>
<!--   操作时间   -->
      <div slot="itemCreateOrUpdateAboutSlot" slot-scope="row">
        <div class="color-gray">
          创建时间：<span class="color-black">{{ row.createTime }}</span>
        </div>
        <div class="color-gray">
          操作人ID：<span class="color-blue">{{ row.adminId }}</span>
        </div>
        <div class="color-gray">
          更新时间：<span class="color-black">{{ row.updateTime }}</span>
        </div>
      </div>
<!--   发布状态   -->
      <div slot="itemPublishStatustSlot" slot-scope="row">
        <div :class="messageTemplatePushStatusTypeClassType[row.status]">
          {{ computedMessageTemplatePushStatusTypeListMapText(row.status) }}
        </div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
       <div>
         <a-button
           class="mt-10"
           size="small"
           type="primary"
           @click="handleEditorTemplatePopup(row)"
         >编辑更新</a-button>
       </div>
        <div class="mt-10">
          <a-popconfirm
              title="确定删除当前模版？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteTemplate(row.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  展示内容  -->
    <TextContentPopup ref="textContentPopupEl" />
<!--  编辑  -->
    <EditorTemplatePopup ref="editorTemplatePopupEl" @success="handleActionSuccess"/>
  </div>
</template>
<script>
import {
  messageTemplateTableColumns
} from "@/views/cmsPage/shopManage/messageTemplateList/_data"
import TextContentPopup from "@/views/cmsPage/shopManage/messageTemplateList/_components/TextContentPopup/index.vue"
import {delMessageTemplateApi} from "@/views/cmsPage/shopManage/messageTemplateList/_apis"
import EditorTemplatePopup
  from "@/views/cmsPage/shopManage/messageTemplateList/_components/EditorTemplatePopup/index.vue"
import {
  messageTemplateContentClassType,
  messageTemplateContentTypeMapText,
  messageTemplateIsPopupTypeClassType,
  messageTemplateIsPopupTypeListMapText,
  messageTemplatePushStatusTypeClassType,
  messageTemplatePushStatusTypeListMapText,
  messageTemplatePushUserClassType,
  messageTemplatePushUserListMapText
} from "@/views/cmsPage/shopManage/_data"

export default {
  props: ['pagination', 'list'],
  components: {TextContentPopup, EditorTemplatePopup},
  data() {
    return {
      tableList: messageTemplateTableColumns,
      messageTemplateContentClassType, // 类型样式
      messageTemplatePushUserClassType, // 推送对象样式
      messageTemplateIsPopupTypeClassType, // 是否弹窗显示
      messageTemplatePushStatusTypeClassType, // 模版发布状态
    }
  },
  computed: {
    computedMessageTemplateContentTypeMapText() {
      return (value) => messageTemplateContentTypeMapText(value)
    },
    computedMessageTemplatePushUserListMapText() {
      return (value) => messageTemplatePushUserListMapText(value)
    },
    computedMessageTemplateIsPopupTypeListMapText() {
      return (value) => messageTemplateIsPopupTypeListMapText(value)
    },
    computedMessageTemplatePushStatusTypeListMapText() {
      return (value) => messageTemplatePushStatusTypeListMapText(value)
    }
  },
  methods: {
    /** 编辑模版 */
    handleEditorTemplatePopup(row) {
      this.$refs.editorTemplatePopupEl.show(row)
    },
    /** 展示富文本内容 */
    handleShowTextContent(content) {
      this.$refs.textContentPopupEl.show(content)
    },
    /** 去往链接页面 */
    handleGoToLinkUrl(link) {
      window.open(link)
    },
    /** 展示大图 */
    handleShowBigImg(img) {
      this.$previewImg({
        list: [{img_url: img}],
        baseImgField: "img_url",
        showMute: false,
        current: 0
      });
    },
    /** 删除模版 */
    async handleDeleteTemplate(id) {
      const res = await delMessageTemplateApi({id: id})
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleActionSuccess()
    },
    /** 操作成功 */
    handleActionSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>